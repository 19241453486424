import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {ValidacionIdentificacion} from '../validations/NumerosIdentificacion.js'
import ValidationMessage from '../validations/ValidationMessage.js'

export const Login = () => {

	const navigate = useNavigate();
	const [usuario, setUsuario] = useState('');
	const [message, setMessage] = useState('');
	const validationMessage = new ValidationMessage();

	const handleOnChangeInputUser = e => {
        setUsuario(e.target.value)
    }

	const envia = async (e) => {
		// Para que no se recargue la pagina al hacer click
		e.preventDefault();
		//
		if (!usuario) {
            console.error('El NIF está vacío o no es válido');
            return; // No hace la solicitud si el NIF no es válido
        }

		//
		let target = e.target;
		const $ = e => document.querySelector(e);
		const loginSpan = $('#login-form span');

		//let username = target.username.value;
		let password = target.password.value;

		const validation = ValidacionIdentificacion.ComprobarNombreDocument(usuario);

		if (!validation) {
			loginSpan.innerText = 'Error en iniciar sessió';
			return;
		}

		setUsuario(validation.user);
		setMessage(validationMessage.getMessage(validation.idValidacion));
		
		if (validation.idValidacion === 1 || validation.idValidacion === 3) {
			fetchLogin(validation.user);
		} else {
			loginSpan.innerText = message
		}

		async function fetchLogin(user) {
			const options = {
				method: 'POST',
				body: JSON.stringify({ user, password }),
				headers: { "Content-type": "application/json; charset=UTF-8" },
			};

			fetch('/api/login', options)
				.then(async response => {
					const data = await response.json();
					// check for error response
					if (!response.ok) {
						// get error message from body or default to response status
						const error = (data && data.message) || response.status;
						return Promise.reject(error);
					} else {
						setTimeout(() => {
							navigate('/inicio', { state: { nif: user } })
						}, 2000)
					}
				})
				.catch(error => {
					loginSpan.innerText = 'Error en iniciar sessió';
				});
		};
	}

	return (
		<>
			<div className='form-container'>
				<form id='login-form' onSubmit={envia}>
					<h3>Connexió per a usuaris registrats</h3>
					<div className='info-login'>
						<p>Aquesta fase del procés NOMÉS està adreçada:</p>
						<ul>
							<li>
								<p>A les persones aspirants que han APROVAT l'examen de la prova d'oposició i la prova de coneixements de llengua catalana del procés d'Estabilització, fase 2 i fase 3.</p>
							</li>
							<li>
								<p>Als professionals sol·licitants d'un nivell de carrera professional a la convocatòria 2024 (sol·licituds de 01.12.2023 a 31.12.2023).</p>
							</li>
						</ul>
					</div>
					<h6>Identifica't per iniciar la sessió.</h6>
					<input type="text" id="username" onChange={ handleOnChangeInputUser }
						placeholder="Document d'identitat" name="username" value={usuario}
						autoComplete="off" autoCapitalize="none" autoCorrect="off" required />

					<input type="password" id="password"
						placeholder='Clau' name="password"
						autoComplete="off" autoCapitalize="none" autoCorrect="off"
						aria-describedby="" required />

					<button type="submit">ACCEDIR</button>
					<br />
					<span>&nbsp;</span>
				</form>
			</div>
		</>

	)
}