import { MainRouter } from './routers/MainRouter';
import logo_gencat from './images/Gencat-logo_white.svg'
import logo_ics_2 from './images/logo_ics_white.png'


function
  App() {

  return (
    <div className="App">
      <div className="App-header">
        <div className='App-logo'>
          <img src={logo_ics_2} className="logo-ics" alt="logo" />
          <img src={logo_gencat} className="logo-gencat" alt="logo" />
        </div>
        
        <div className='App-main'>
          <MainRouter />
        </div>
      </div>
    </div>
  );
}

export default App;
