import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export const Aviso = ({ open, setOpen, setWarned, nif }) => {

    const handleSi = (e) => {
        e.preventDefault();

        fetchUpdateRespuestaSi();

        setOpen(false);
        setWarned(1);
    }

    const handleNo = (e) => {
        e.preventDefault();

        fetchUpdateRespuestaNo();

        setOpen(false);
        setWarned(1);
    }

    function fetchUpdateRespuestaSi() {
        fetch(`/api/db/updateFechaRespuestaSi/${nif}`, { method: 'POST' })
            .then(async response => {
                if (!response.ok) {
                    alert('Error al guardar la resposta');
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    function fetchUpdateRespuestaNo() {
        fetch(`/api/db/updateFechaRespuestaNo/${nif}`, { method: 'POST' })
            .then(async response => {
                if (!response.ok) {
                    alert('Error al guardar la resposta');
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    return (
        <>
            <Popup open={ open } position="right center">
                <div className='modal'>
                    <form className='popup-form'>
                        <p>Informo que aportaré certificat de serveis prestats de caràcter temporal en l'ambit del SISCAT (no ICS).</p>
                        <div className='popup-actions'>
                            <input className='aviso-si' onClick={handleSi} type="submit" value="SI" />
                            <input className='aviso-no' onClick={handleNo} type="submit" value="NO" />
                        </div>
                    </form>
                </div>
            </Popup>
        </>
    );
}
