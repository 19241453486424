import React, { useEffect, useState } from 'react'

export const CreaDescarga = ({ pNif }) => {

    const [usuario, setUsuario] = useState(null);

    useEffect(() => {

        if (!pNif) {
            console.error('El NIF está vacío o no es válido');
            return; // No hace la solicitud si el NIF no es válido
        }

        const fetchUsuarioCategoria = async () => {
            try {
                const res = await fetch(`/api/db/getUsuarioCategoria/${pNif}`, { method: 'GET' });
                if (!res.ok) {
                    const errorData = await res.text();
                    throw new Error(errorData);
                }

                const usuarioData = await res.json();
                setUsuario(usuarioData);
            } catch (error) {
                console.error('Error: Al recuperar les dades del usuari');
            }
        };

        fetchUsuarioCategoria();

    }, [pNif]);

    const getDescarga = async (e, directorioDescarga, categoria, idCategoria) => {
        e.preventDefault();

        try {
            const response = await fetch(`/api/descarga/${directorioDescarga}/${pNif}`, { method: 'GET' });
            if (response.ok)
                fetchUpdateDescarga(pNif, idCategoria);
            else
                throw new Error('Error a la descàrrega');

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = categoria + '_' + pNif;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            alert(error);
        }
    }

    function fetchUpdateDescarga(nif, idCategoria) {
        fetch(`/api/db/updateFechaDescarga/${idCategoria}/${nif}`, { method: 'POST' })
            .then(async response => {
                if (!response.ok) {
                    console.error('Error en guardar la data de descàrrega!');
                }
            })
            .catch(error => {
                console.error('Error en guardar la data de descàrrega!');
            });
    }

    return (
        <>
            {usuario && usuario.length > 0 ? (
                usuario.map((usu) => (
                    <form key={usu.idusercategoria} className='form-container-dw' onSubmit={(e) => getDescarga(e, usu.directorioDescarga, usu.categoria, usu.idCategoria)} >
                        <h2>{usu?.descripcion} </h2>
                        <button className='btnfci' type="submit" >DESCARREGAR</button>
                    </form>
                ))
            ) : (
                <p className='pAvisoNoData'>No s'han trobat documents per a la seva descàrrega</p>
            )}
        </>
    )
}
