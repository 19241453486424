import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Aviso } from './Aviso';
import { CreaDescarga } from './CreaDescarga';

export const Inicio = () => {

    const [nif, setNif] = useState('');
    const [warned, setWarned] = useState(0);
    const [open, setOpen] = useState(false);

    const location = useLocation()
    const navigate = useNavigate()

    const fetchInicio = useCallback(() => {
        fetch('/api/inicio')
            .then(async response => {
                if (!response.ok) navigate('/');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [navigate]); // <- Dependencia si se usa navigate dentro de fetchInicio

    // Se ejecuta al cargar este componente
    useEffect(() => {
        fetchInicio();
        setNif(location.state?.nif || '');
    }, [fetchInicio, location.state?.nif]);

    const fetchLogout = e => {
        e.preventDefault();

        fetch('/api/logout', { method: 'POST' })
            .then(async response => {
                if (response.ok) navigate('/');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    return (
        <>
            { /* Formulario para editar la pelicula guardada */}
            {warned === 0 && (
                <Aviso open={open} setOpen={setOpen} setWarned={setWarned} nif={nif} />
            )}
            <div className='divNavBar'>
                <nav className='navLogout'>
                    <ul>
                        
                        <li>
                            <p className='btnLogout' onClick={fetchLogout} >Tanca sessió</p>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className='form-container-inicio'>
                <CreaDescarga pNif = {nif} />
            </div>
        </>
    )
}
