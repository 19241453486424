export class ValidacionIdentificacion {
    // ComprobarNombreDocument
    //
    static _bMostrarErrores = false;
    static MostrarErrores(bool) {
        //Mostrará/No mostrará errores de validación en el NIF/NIE
        this._bMostrarErrores = bool;
    }

    /*****************************************************************************************************/
    /* NUEVA VALIDACION NIF/NIEs 10/03/2009                                                     
    
             */
    /*****************************************************************************************************/
    static ComprobarNombreDocument(username) {
        let sIdentificacion = this.ValidateFormatDocument(username);
        if (sIdentificacion !== '') {
            let iResultado = this.valida_nif_nie(sIdentificacion);
            switch (iResultado) {
                case -1:
                    sIdentificacion = sIdentificacion.substring(0, sIdentificacion.length - 1);
                    sIdentificacion = this.AddNIFNIELetter(sIdentificacion);
                    const idValidacionNIF_NIE = this.valida_nif_nie(sIdentificacion)
                    if (idValidacionNIF_NIE !== 1) {
                        const result = { user: sIdentificacion, idValidacion: idValidacionNIF_NIE }
                        return result;
                    }
                    break;
                case -3:
                    sIdentificacion = sIdentificacion.substring(0, sIdentificacion.length - 1);
                    sIdentificacion = this.AddNIFNIELetter(sIdentificacion);

                    if (this.valida_nif_nie(sIdentificacion) !== 3) {
                        return { user: sIdentificacion, idValidacion: iResultado }
                    }
                    break;
                case 0:
                    return { user: sIdentificacion, idValidacion: iResultado }
                default:
                    return { user: sIdentificacion, idValidacion: iResultado }
            }
        }
    }

    static ValidateFormatDocument(sDocumento) {
        //NIF

        var temp = this.trim(sDocumento);
        var re = new RegExp("^[0]?[0-9]{6,8}([a-zA-Z])?$");
        if (re.test(temp)) {
            var len = 10;
            if (!isNaN(temp))
                len = 9; //Dejamos espacio para que el ComprobarNombreDocument ponga la letra

            for (let i = 0; temp.length < len; i++) {
                //Añadimos ceros a la izquierda
                temp = "0" + temp;
            }

            temp = temp.toUpperCase()
        }

        //NIE sin letra
        re = new RegExp("^[a-zA-Z]{1}([0-9]{7})$");
        if (re.test(temp)) {
            temp = temp.toUpperCase()
            //Añadimos espacio ya que en el SIP los NIE se guardan con formato X1234567 A
            temp += " ";
        }

        //Validamos NIE sin espacio
        re = new RegExp("^[a-zA-Z]{1}[0-9]{7}[a-zA-Z]{1}$");
        if (re.test(temp)) {
            temp = temp.substring(0, temp.length - 1) + " " + temp.charAt(temp.length - 1);
            temp = temp.toUpperCase()
        }

        //Añadir letra a los NIF/NIE
        temp = this.AddNIFNIELetter(temp);

        return temp;
    }

    static valida_nif_nie(a) {
        //http://compartecodigo.com/javascript/validar-nif-cif-nie-segun-ley-vigente-31.html
        //Retorna: 
        //  * 0 = Error validacion documento
        //  * 1 = NIF ok
        //  *-1 = letra NIF error
        //  * 3 = NIE ok, 
        //  *-3 = NIE error

        var temp = a.toUpperCase();
        var cadenadni = "TRWAGMYFPDXBNJZSQVHLCKE";

        if (temp !== '') {
            //si no tiene un formato valido devuelve error
            if (!/^[A-Z]{1}[0-9]{7}[\s]{1}[A-Z0-9]{1}$/.test(temp) &&
                !/^[0]{1}[0-9]{8}[A-Z]{1}$/.test(temp)) {
                return 0;
            }

            //comprobacion de NIFs estandar con letra
            if (/^[0]{1}[0-9]{8}[A-Z]{1}$/.test(temp)) //NIFs se guardan con 0 delante en ICS
            {
                let posicion = a.substring(9, 0) % 23;
                let letra = cadenadni.charAt(posicion);
                var letradni = temp.charAt(9);
                if (letra === letradni) {
                    return 1;
                }
                else {
                    return -1; //letra NIF incorrecta
                }
            }

            //XYZ
            if (/^[XYZ]{1}/.test(temp)) {
                let pos = this.str_replace(['X', 'Y', 'Z'], ['0', '1', '2'], temp).substring(0, 8) % 23;
                if (a.charAt(9) === cadenadni.substring(pos, pos + 1)) {
                    return 3;
                }
                else {
                    return -3; //letra NIE incorrecta
                }
            }
        }

        return 0;
    }

    static AddNIFNIELetter(NIF) {
        var temp = NIF;
        var cadenadni = "TRWAGMYFPDXBNJZSQVHLCKE";
        var posicion;
        var letra;
        var pos;

        if (/^[0]{1}[0-9]{8}$/.test(temp)) //NIF
        {
            posicion = temp.substring(9, 0) % 23;
            letra = cadenadni.charAt(posicion);
            temp = temp + letra;
            temp = temp.toUpperCase()

        }

        if (/^[A-Z]{1}[0-9]{7}[\s]?$/.test(temp)) //NIE
        {
            pos = this.str_replace(['X', 'Y', 'Z'], ['0', '1', '2'], temp).substring(0, 8) % 23;
            letra = cadenadni.substring(pos, pos + 1);
            temp = temp + letra;
            temp = temp.toUpperCase()
        }

        return temp;
    }

    static str_replace(search, replace, subject) {
        let searchArray = Array.isArray(search) ? search : [search];
        let replaceArray = Array.isArray(replace) ? replace : [replace];
        let subjectArray = Array.isArray(subject) ? subject : [subject];

        for (let i = 0; i < subjectArray.length; i++) {
            let tempSubject = subjectArray[i];
            for (let j = 0; j < searchArray.length; j++) {
                const searchValue = searchArray[j];
                const replaceValue = replaceArray[j] !== undefined ? replaceArray[j] : replaceArray[0];
                tempSubject = tempSubject.split(searchValue).join(replaceValue);
            }
            subjectArray[i] = tempSubject;
        }
        return Array.isArray(subject) ? subjectArray : subjectArray[0];
    }

    /*****************************************************************************************************/
    /* END VALIDACION NIF/NIEs 10/03/2009                                                                */
    /*****************************************************************************************************/

    // ComprobarFormatNombreId
    static ComprobarFormatNombreId(sNombreId) {

        //*****************************************************************
        //**** MODIFICADO 11/01/2008
        //**** Ahora los NIF's serán de 10 chars (no de 9 como actualmente)
        //**** ANTES => 12345678A
        //**** AHORA => 012345678A
        //*****************************************************************
        //
        // === CODIGO ANTIGUO NIF 9 chars ===
        //
        //var sResultat = '';
        //if (sNombreId.length == 8 || sNombreId.length == 9 || sNombreId.length == 10)
        //{
        //    
        //    
        //    if(sNombreId.length == 9 || sNombreId.length == 10)
        //    {        
        //        sNombreId = sNombreId.substring(0, sNombreId.length - 1);
        //        sResultat = 'C';
        //    }
        // === FIN CODIGO ANTIGUO NIF 9 chars ===
        //
        // === CODIGO NUEVO NIF 10 chars ===
        var sResultat = '';
        if (sNombreId.length === 9 || sNombreId.length === 10) {
            if (sNombreId.length === 10) {
                sNombreId = sNombreId.substring(0, sNombreId.length - 1);
                sResultat = 'C';
            }
            // === FIN CODIGO NUEVO NIF 10 chars ===
            //*****************************************************************
            var sTipus = sNombreId.substring(0, 1);
            switch (sTipus.toUpperCase()) {
                case 'X':
                    sResultat = this.EsSencer(sNombreId.substring(1, sNombreId.length)) ? sResultat + 'X' : '';
                    break;
                case 'Y':
                    sResultat = this.EsSencer(sNombreId.substring(1, sNombreId.length)) ? sResultat + 'Y' : '';
                    break;
                default:
                    if (this.EsSencer(sTipus)) {
                        if (this.EsSencer(sNombreId)) {
                            sResultat = sResultat + 'E';
                        }
                        else {
                            sResultat = '';
                        }
                    }
                    else
                        sResultat = '';
                    break;
            }
        }
        return sResultat;
    }// ComprobarFormatNombreId

    // this.EsSencer
    static EsSencer(sNombre) {
        sNombre = this.trim(sNombre);

        if (isNaN(sNombre))
            return false;

        //if(sNombre.substring(0, 1) == '0' && sNombre.length > 1) 
        //    sNombre = sNombre.substring(1, sNombre.length);
        sNombre = this.TreureCerosTamanyMinim(sNombre, 1);

        var sAux = sNombre + '';

        sNombre = parseInt(sNombre) + '';

        if (sNombre !== sAux)
            return false;
        else
            return true;
    }// this.EsSencer

    //Treu els ceros de l'esquerra d'un string (sNombre) sense fer que la llargada sigui més petita que la llargada mínima (iMinim) 
    static TreureCerosTamanyMinim(sNombre, iMinim) {
        var iLong = sNombre.length;

        for (let i = 0; i < iLong - 1; i++) {
            if (sNombre.length <= iMinim)
                return sNombre;

            if (sNombre.substring(0, 1) === '0')
                sNombre = sNombre.substring(1, sNombre.length);
            else
                return sNombre;
        }
        return sNombre;
    }

    static TrobarLletra(nNombreId) {
        /* ojo => no fuinciona para nies que empiezan por Y */
        var sCadenaLletra = "TRWAGMYFPDXBNJZSQVHLCKE";
        var nPosicio = nNombreId % 23;
        return sCadenaLletra.substring(nPosicio, nPosicio + 1);
    }

    static ltrim(s) {
        return s.replace(/^\s+/, "");
    }

    static rtrim(s) {
        return s.replace(/\s+$/, "");
    }

    static trim(s) {
        return this.rtrim(this.ltrim(s));
    }

}

